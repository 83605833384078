import React from "react";
import {Helmet} from "react-helmet";

const Error404Header = ({title}:{title: string}) => {

    return (
        <>

        <Helmet>
            <title>{title}</title>
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap" rel="stylesheet" />
            <link rel="stylesheet" href="/assets/css/vendor.min.css" />
            <link rel="stylesheet" href="/assets/css/theme.min.css?v=1.0" />
            {/*<link rel="stylesheet" href="/assets/css/styles.op-shipping.css" />*/}

            <script src="/assets/js/vendor.min.js"></script>
            <script src="/assets/js/theme.min.js"></script>
        </Helmet>
        <header id="header" className="navbar navbar-height navbar-light navbar-absolute-top bg-white mt-2 mb-10">
            <div className="container">
                <a className="navbar-brand mx-auto" href="/" aria-label="Unify">
                    <img className="navbar-brand-logo" src="/assets/svg/logos/logo.svg" alt="Image Description" />
                </a>
            </div>
        </header>
        </>
    );
}
export default Error404Header;